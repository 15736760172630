@charset 'UTF-8';
::moz-selection {
  background-color: #6c757d;
  color: #000;
}
::selection {
  background-color: #6c757d;
  color: #000;
}
html,
body {
  font-family: 'Noto Sans', sans-serif;
  height: 100%;
}
body {
  padding: 1rem;
  background-color: #212121;
  color: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
strong, b,
.btn,
h1, .h1 {
  font-weight: 600;
}
a {
  color: #6c757d;
  transition: color .1s;
  text-decoration: underline;
}
a:hover,
a:focus {
  color: #5a6268;
}
.btn {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
header {
  text-align: center;
}
header > img,
header > a > img {
  width: 15rem;
  max-width: 100%;
}
header > h1 {
  margin: 2rem 0 0;
}
header > p {
  margin: 1rem 0 0;
}
main {
  margin: 2rem 0 0;
  width: 25rem;
  max-width: 100%;
}
.required > label::after,
.required > .input-group > label > .input-group-text::after {
  content: '*';
  color: #dc3545;
}
.input-group > label {
  width: 8rem;
  margin-bottom: 0;
}
.input-group > label > .input-group-text {
  width: 100%;
  font-size: .85rem;
  font-weight: 600;
}
.form-control {
  background-color: #181818;
  border-color: #444;
  color: #eee;
  transition: border-color .1s;
}
.input-group-text {
  color: #000;
  background-color: #ddd;
  border-color: #ccc;
}
.alert,
.form-control,
.input-group-text,
.btn {
  border-radius: 2px;
}
.alert {
  white-space: pre-wrap;
}
.form-control:focus {
  background-color: #181818;
  box-shadow: none;
  border-color: #ccc;
  color: inherit;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #121212;
}
textarea.form-control {
  min-height: calc(1.5em + .75rem + 2px);
}
nav > ul {
  list-style: none;
  margin: 2rem 0 0;
  padding: 0;
  text-align: center;
}
nav > ul li + li {
  margin-top: .5rem;
}
footer {
  font-size: .85rem;
  color: #6c757d;
  margin-top: 2rem;
}
#pageLoader {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1050;
  background-color: rgba(128, 128, 128, .5);
  color: #fff;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}
#pageLoader::after {
  content: attr(data-text);
}
html.loading #pageLoader {
  display: flex;
}